import React from 'react';
import { st, classes } from './Body.st.css';
import { AlignmentOptions, MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { BodyViewModel } from '../../../../service-page-view-model/body-view-model/bodyViewModel';

type BodyProps = {
  viewModel: BodyViewModel;
  width: string;
  bodyAlignment: AlignmentOptions;
  className?: string;
};

const BodyComponent: React.FC<BodyProps> = ({
  viewModel,
  width,
  bodyAlignment,
  className,
  children,
}) => {
  return (
    <RenderLocationProvider value={MainComponents.BODY}>
      <div
        style={{ flexBasis: width }}
        className={st(classes.root, { alignment: bodyAlignment }, className)}
      >
        {viewModel.message && (
          <div
            role="status"
            data-hook="no-book-button-message"
            className={classes.notificationBanner}
          >
            <SectionNotification type="default">
              <SectionNotification.Text>
                {viewModel.message}
              </SectionNotification.Text>
            </SectionNotification>
          </div>
        )}
        <div>{children}</div>
      </div>
    </RenderLocationProvider>
  );
};

export default BodyComponent;
