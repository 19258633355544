import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export default createStylesParams<{
  headerHeight: StyleParamType.Number;
  headerSidePadding: StyleParamType.Number;
  headerImageOpacity: StyleParamType.Number;
  headerBackgroundColor: StyleParamType.Color;
  headerTitleFont: StyleParamType.Font;
  headerTitleColor: StyleParamType.Color;
  bodyBackgroundColor: StyleParamType.Color;
  bodyServiceTitleFont: StyleParamType.Font;
  bodyServiceTitleColor: StyleParamType.Color;
  bodyServiceTaglineFont: StyleParamType.Font;
  bodyServiceTaglineColor: StyleParamType.Color;
  bodyTitleFont: StyleParamType.Font;
  bodyTitleColor: StyleParamType.Color;
  bodyTextFont: StyleParamType.Font;
  bodyTextColor: StyleParamType.Color;
  bodyDividerWidth: StyleParamType.Number;
  bodyDividerColor: StyleParamType.Color;
  sidebarBackgroundColor: StyleParamType.Color;
  sidebarTitleColor: StyleParamType.Color;
  sidebarTitleFont: StyleParamType.Font;
  sidebarTextColor: StyleParamType.Color;
  sidebarTextFont: StyleParamType.Font;
  sidebarServiceTitleColor: StyleParamType.Color;
  sidebarServiceTitleFont: StyleParamType.Font;
  sidebarServiceTaglineColor: StyleParamType.Color;
  sidebarServiceTaglineFont: StyleParamType.Font;
  sidebarButtonTextColor: StyleParamType.Color;
  sidebarButtonTextFont: StyleParamType.Font;
  sidebarButtonBackgroundColor: StyleParamType.Color;
  sidebarButtonBorderColor: StyleParamType.Color;
  sidebarButtonBorderWidth: StyleParamType.Number;
  sidebarButtonCornerRadius: StyleParamType.Number;
  sidebarButtonHoverTextColor: StyleParamType.Color;
  sidebarButtonHoverBackgroundColor: StyleParamType.Color;
  sidebarButtonHoverBorderColor: StyleParamType.Color;
  sidebarSidePadding: StyleParamType.Number;
  headerButtonTextColor: StyleParamType.Color;
  headerButtonTextFont: StyleParamType.Font;
  headerButtonBackgroundColor: StyleParamType.Color;
  headerButtonBorderColor: StyleParamType.Color;
  headerButtonBorderWidth: StyleParamType.Number;
  headerButtonCornerRadius: StyleParamType.Number;
  headerButtonHoverTextColor: StyleParamType.Color;
  headerButtonHoverBackgroundColor: StyleParamType.Color;
  headerButtonHoverBorderColor: StyleParamType.Color;
  bodyButtonTextColor: StyleParamType.Color;
  bodyButtonTextFont: StyleParamType.Font;
  bodyButtonBackgroundColor: StyleParamType.Color;
  bodyButtonBorderColor: StyleParamType.Color;
  bodyButtonBorderWidth: StyleParamType.Number;
  bodyButtonCornerRadius: StyleParamType.Number;
  bodyButtonHoverTextColor: StyleParamType.Color;
  bodyButtonHoverBackgroundColor: StyleParamType.Color;
  bodyButtonHoverBorderColor: StyleParamType.Color;
  bodySidePadding: StyleParamType.Number;
  bodySpaceBetweenSections: StyleParamType.Number;
  firstSectionSpaceFromTheTop: StyleParamType.Number;
  serviceTitleFont: StyleParamType.Font;
  serviceTitleColor: StyleParamType.Color;
  serviceTaglineFont: StyleParamType.Font;
  serviceTaglineColor: StyleParamType.Color;
  backgroundColor: StyleParamType.Color;
  detailsInnerPadding: StyleParamType.Number;
  detailsBoxFont: StyleParamType.Font;
  detailsBoxTextColor: StyleParamType.Color;
  detailsBoxColor: StyleParamType.Color;
  detailsBoxBorderColor: StyleParamType.Color;
  detailsBoxBorderWidth: StyleParamType.Number;
  scheduleBoxPadding: StyleParamType.Number;
  scheduleBackgroundColor: StyleParamType.Color;
  scheduleBorderColor: StyleParamType.Color;
  scheduleBorderWidth: StyleParamType.Number;
  scheduleDividerColor: StyleParamType.Color;
  scheduleDividerWidth: StyleParamType.Number;
  scheduleDatesFont: StyleParamType.Font;
  scheduleDatesColor: StyleParamType.Color;
  scheduleHoursFont: StyleParamType.Font;
  scheduleHoursColor: StyleParamType.Color;
  scheduleDetailsFont: StyleParamType.Font;
  scheduleDetailsColor: StyleParamType.Color;
  scheduleLinkFont: StyleParamType.Font;
  scheduleLinkColor: StyleParamType.Color;
}>({
  headerHeight: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 220 : 360),
  },
  headerSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  headerImageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  bodyBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 40,
    }),
  },
  headerTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bodyTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 20,
    }),
  },
  bodyTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  bodyTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  bodyTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  bodySidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  firstSectionSpaceFromTheTop: {
    type: StyleParamType.Number,
    getDefaultValue: () => 80,
  },
  bodySpaceBetweenSections: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
  },
  sidebarBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 20,
    }),
  },
  sidebarTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  sidebarServiceTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarServiceTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 20,
    }),
  },
  sidebarServiceTaglineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarServiceTaglineFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  sidebarSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  serviceTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 40,
    }),
  },
  serviceTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  serviceTaglineFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 16,
    }),
  },
  serviceTaglineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsInnerPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  detailsBoxFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  detailsBoxTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsBoxColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  detailsBoxBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  detailsBoxBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  sidebarButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  sidebarButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  sidebarButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  sidebarButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  sidebarButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarButtonHoverBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  sidebarButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0.7),
  },
  bodyButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bodyButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  bodyButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  bodyButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0),
  },
  bodyButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  bodyButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  bodyButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bodyButtonHoverBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  bodyButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0.7),
  },
  headerButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  headerButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  headerButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  headerButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerButtonHoverBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0.7),
  },
  headerButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  bodyDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  bodyDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  bodyServiceTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 40,
    }),
  },
  bodyServiceTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  bodyServiceTaglineFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  bodyServiceTaglineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleBoxPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 28,
  },
  scheduleBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  scheduleBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  scheduleBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  scheduleDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  scheduleDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  scheduleDatesFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  scheduleDatesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleHoursFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  scheduleHoursColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleDetailsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  scheduleDetailsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  scheduleLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  scheduleLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
});
