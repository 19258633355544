import React from 'react';

export const NavigationContext = React.createContext<{
  navigateToCalendar: () => void;
}>({
  navigateToCalendar: () => {},
});

export const NavigationConsumer = NavigationContext.Consumer;
export const NavigationProvider = NavigationContext.Provider;

export const useNavigation = () => React.useContext(NavigationContext);
